@import './variables';
@import 'mssvariables';

.cant-change {
  background-color: theme-color-2022('2022--light-grey') !important;
  opacity: 1 !important;
}

.col-chart {
  overflow-x: auto;
  border: 1px solid map-get($colors, lightgrey);
}

.node-format {
  cursor: pointer;
  background-color: map-get($colors, primary);
  border-radius: 5px;
  color: theme-color-2022('2022--white');
  box-shadow: 0 2px 8px rgb(0 0 0 / 80%) !important;

  &.selected-node-format {
    background-color: lighten(map-get($colors, primary), 40%);
    border-radius: 5px;
    color: theme-color-2022('2022--black');
    box-shadow: 0 2px 8px rgb(0 0 0 / 80%) !important;
  }
}

.edit-node-format {
  cursor: pointer;
  background-color: lighten(map-get($colors, primary), 40%);
  border-radius: 5px;
  color: theme-color-2022('2022--black');
  box-shadow: 0 2px 8px rgb(0 0 0 / 80%) !important;

  &.selected-node-format {
    background-color: map-get($colors, primary);
    border-radius: 5px;
    color: theme-color-2022('2022--white');
    box-shadow: 0 2px 8px rgb(0 0 0 / 80%) !important;
  }
}

.read-only-flow {
  .node-format,
  .edit-node-format {
    min-width: 100px !important;
  }
}

.edit-flow {
  .node-format,
  .edit-node-format {
    min-width: 70px !important;
  }
}

.resize-none {
  resize: none;
}
